import { computed, inject } from '@angular/core';
import { patchState, signalStoreFeature, withComputed, withHooks, withMethods, withState } from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { MsalService } from '@shared/authentication/msal.service';
import { Role } from '@shared/types/configuration';
import { User } from 'msal';
import { pipe, switchMap, toArray, tap } from 'rxjs';

interface IUserDataState {
    isLoggedIn: boolean,
    username: string | undefined,
    roles: string[],
    isAdmin: boolean,
    userEmail: string | undefined
}

const initialState: IUserDataState = {
    isLoggedIn: false,
    username: undefined,
    roles: [],
    isAdmin: false,
    userEmail: undefined
}

export function withUserData() {
    return signalStoreFeature(
        withState<IUserDataState>(initialState),
        withComputed((store) => ({
            initials: computed(() => splitName(store.username())),
            isApimManager: computed(() => store.roles().includes('APIMManager')),
        })),
        withMethods((store, msalService = inject(MsalService)) => ({
            hasAnyRole(...roles: Role[]): boolean {
                return msalService.hasAnyRole(...roles);
            },
            initRoles: rxMethod<void>(
                pipe(
                    switchMap(() => msalService.getUserRoles$().pipe(
                        toArray(),
                        tap(roles => patchState(store, { roles })),
                    )),
                )
            ),
        })
        ),
        withHooks({
            onInit: (store, msalService = inject(MsalService)) => {
                patchState(store, {
                    isLoggedIn: msalService.isOnline(),
                    username: msalService.getUser()?.name,
                    isAdmin: msalService.isAdmin()(),
                    userEmail: getUserEmail(msalService.getUser())
                });

                if (msalService.isOnline()) {
                    store.initRoles();
                }
            }
        }),
    );
}

function splitName(fullName: string | undefined) {
    return fullName
        ? fullName
            .split(' ')
            .map(value => value[0])
            .join('')
            .substring(0, 2)
            .toLocaleUpperCase()
        : '';
}

function getUserEmail(user: User | undefined): string | undefined {
    if (!user || !user.idToken) {
        return undefined;
    }

    return (user.idToken as any)['email'];
}